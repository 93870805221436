<template>
  <main ref="main" class="main" :class="{ 'mb-md-0': !$slots.text }">
    <div v-if="mq.smMinus" class="plan-search-header text-white">
      <div class="plan-search-header__background">
        <img
          loading="lazy"
          width="2160"
          height="1350"
          src="@i/plan-archive.jpg"
          alt="План корпусов"
        />
      </div>
      <div class="plan-search-header__title">
        <div class="container">
          <h1 class="h3" v-html="h1"></h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="plan-search">
        <div class="row no-gutters flex-nowrap">
          <div class="col-auto">
            <div class="left" :class="{ mobileFilterShow: mobileFilterShow }">
              <Backlink
                class="d-none d-lg-block"
                :to="{ name: 'PlanArchive' }"
                text="К&nbsp;визуальному выбору"
              />
              <div v-if="!loading" class="filter">
                <div class="filter-control">
                  <div class="filter-control__label">Количество комнат</div>
                  <ul class="filter-control__list">
                    <li
                      v-for="room in roomsFilter"
                      :key="room"
                      :class="{ active: room == roomsFilterActive }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="roomsFilterControl(room)"
                        >{{ room === "0" ? "Ст" : room }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="filter-control">
                  <div class="filter-control__label">Корпус</div>
                  <ul class="filter-control__list">
                    <li
                      v-for="building in buildingsFilter"
                      :key="building"
                      :class="{ active: building == buildingsFilterActive }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="buildingsFilterControl(building)"
                        >{{ building }}</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="filter-control">
                  <div class="filter-control__label">Тип</div>
                  <ul class="filter-control__list tags">
                    <li
                      v-for="type in typesFilter"
                      :key="type"
                      :class="{
                        active: Object.keys(type)[0] == typesFilterActive,
                      }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="typesFilterControl(Object.keys(type)[0])"
                        v-html="Object.values(type)[0]"
                      />
                    </li>
                  </ul>
                </div>
                <div class="filter-slider">
                  <div class="filter-slider__label">
                    <span>Площадь</span>
                  </div>
                  <div class="filter-inputs">
                    <div class="filter-inputs__item">
                      <input
                        v-model.lazy="squareFilter[0]"
                        class="form__input"
                        type="text"
                        inputmode="numeric"
                      />
                      <div class="filter-inputs__label">от</div>
                      <div class="filter-inputs__label">м&sup2;</div>
                    </div>
                    <div class="filter-inputs__item">
                      <input
                        v-model.lazy="squareFilter[1]"
                        class="form__input"
                        type="text"
                        inputmode="numeric"
                      />
                      <div class="filter-inputs__label">до</div>
                      <div class="filter-inputs__label">м&sup2;</div>
                    </div>
                  </div>
                  <Slider
                    v-model="squareFilter"
                    :min="squareMinMax[0]"
                    :max="squareMinMax[1]"
                    :format="squareFilterFormat"
                  />
                </div>
                <div class="filter-slider">
                  <div class="filter-slider__label">
                    <span>Этаж</span>
                  </div>
                  <div class="filter-inputs">
                    <div class="filter-inputs__item">
                      <input
                        v-model.lazy.number="floorFilter[0]"
                        class="form__input"
                        type="text"
                        inputmode="numeric"
                      />
                      <div class="filter-inputs__label">от</div>
                    </div>
                    <div class="filter-inputs__item">
                      <input
                        v-model.lazy.number="floorFilter[1]"
                        class="form__input"
                        type="text"
                        inputmode="numeric"
                      />
                      <div class="filter-inputs__label">до</div>
                    </div>
                  </div>
                  <Slider
                    v-model="floorFilter"
                    :min="floorMinMax[0]"
                    :max="floorMinMax[1]"
                  />
                </div>
                <div class="filter-slider mb">
                  <div class="filter-slider__label">
                    <span>Стоимость</span>
                  </div>
                  <div class="filter-inputs">
                    <div class="filter-inputs__item">
                      <input
                        :value="costFilterFormatPlain(costFilter[0])"
                        class="form__input"
                        type="text"
                        inputmode="numeric"
                        @change="
                          costFilter[0] = costFilterFormatBack(
                            $event.target.value
                          )
                        "
                      />
                      <div class="filter-inputs__label">от</div>
                      <div class="filter-inputs__label">млн</div>
                    </div>
                    <div class="filter-inputs__item">
                      <input
                        :value="costFilterFormatPlain(costFilter[1])"
                        class="form__input"
                        type="text"
                        inputmode="numeric"
                        @change="
                          costFilter[1] = costFilterFormatBack(
                            $event.target.value
                          )
                        "
                      />
                      <div class="filter-inputs__label">до</div>
                      <div class="filter-inputs__label">млн</div>
                    </div>
                  </div>
                  <Slider
                    v-model="costFilter"
                    :min="costMinMax[0]"
                    :max="costMinMax[1]"
                    :format="costFilterFormat"
                  />
                </div>
                <!-- <div class="filter-control">
                  <div class="filter-control__label">Отделка</div>
                  <ul class="filter-control__list tags">
                    <li
                      v-for="decoration in decorationsFilter"
                      :key="decoration"
                      :class="{
                        active:
                          decorationsFilterActive.length &&
                          decorationsFilterActive.includes(decoration),
                      }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="decorationsFilterControl(decoration)"
                      >
                        {{ !decoration ? "Без отделки" : "White Box" }}
                      </a>
                    </li>
                  </ul>
                </div> -->
                <div class="filter-control">
                  <div class="filter-control__label">Особенности</div>
                  <ul class="filter-control__list tags">
                    <li
                      v-for="feat in featsFilter"
                      :key="feat"
                      :class="{
                        active:
                          featsFilterActive.length &&
                          featsFilterActive.includes(Object.keys(feat)[0]),
                      }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="featsFilterControl(Object.keys(feat)[0])"
                        v-html="Object.values(feat)[0]"
                      />
                    </li>
                  </ul>
                </div>
                <div class="filter-clear">
                  <a href="javascript:void(0)" @click="clearFilter"
                    >Сбросить фильтр</a
                  >
                </div>
              </div>
              <div class="filter-mobile-btn__close d-md-none">
                <Icon icon="close" @click="mobileFilterHide" />
              </div>
            </div>
            <div
              class="filter-mobile-btn__overlay d-md-none"
              :class="{ mobileFilterShow: mobileFilterShow }"
              @click="mobileFilterHide"
            />
            <div class="filter-mobile-btn__show d-md-none">
              <a
                href="javascript:void(0)"
                class="btn btn-default btn-micro"
                @click="mobileFilterShow = true"
              >
                Изменить фильтр
              </a>
            </div>
            <div
              class="filter-mobile-btn__hide d-md-none"
              :class="{ mobileFilterShow: mobileFilterShow }"
            >
              <a
                href="javascript:void(0)"
                class="btn btn-default btn-small"
                @click="mobileFilterHide"
              >
                Показать
                {{ filteredFlats.length }}
                {{
                  $filters.declOfNum(filteredFlats.length, [
                    "вариант",
                    "варианта",
                    "вариантов",
                  ])
                }}
              </a>
            </div>
          </div>
          <div v-if="!loading" class="col">
            <div class="right">
              <h1 v-if="mq.mdPlus" class="h3" v-html="h1"></h1>
              <div class="count h4">
                {{
                  $filters.declOfNum(filteredFlats.length, [
                    "Найден",
                    "Найдено",
                    "Найдено",
                  ])
                }}
                {{ filteredFlats.length }}&nbsp;{{
                  $filters.declOfNum(filteredFlats.length, [
                    "вариант",
                    "варианта",
                    "вариантов",
                  ])
                }}{{
                  filteredFlats.length === 0
                    ? ". Пожалуйста, измените параметры поиска."
                    : ""
                }}
              </div>
              <Flats
                v-if="mq.lgPlus"
                :flats="filteredFlats"
                :banners="banners"
                @bannerClick="handleBannerClick"
              />
              <FlatsMobile
                v-else
                :flats="filteredFlats"
                :banners="banners"
                @bannerClick="handleBannerClick"
              />
            </div>
          </div>
        </div>
      </div>
      <slot v-if="!loading" name="text"></slot>
    </div>
    <HomeTeam />
  </main>
</template>

<script>
import axios from "axios";
import _filter from "lodash/filter";
import _minBy from "lodash/minBy";
import _maxBy from "lodash/maxBy";
import _sortBy from "lodash/sortBy";
import _uniq from "lodash/uniq";
import _map from "lodash/map";
import _toNumber from "lodash/toNumber";
import Backlink from "@/components/UI/Backlink.vue";
import Slider from "@vueform/slider";
import Flats from "@/components/FlatsTable.vue";
import FlatsMobile from "@/components/FlatsTableMobile.vue";
import Banner from "@/components/Banner.vue";
import BannerIT from "@/components/BannerIT.vue";
import HomeTeam from "@/components/home/Team.vue";
import BannerMonth from "@/components/BannerMonth.vue";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default {
  components: {
    Slider,
    Flats,
    FlatsMobile,
    Backlink,
    HomeTeam,
  },
  inject: ["mq", "filterStore", "mainStore"],
  props: {
    h1: {
      type: String,
      require: false,
      default: "Подбор и&nbsp;поиск квартир в&nbsp;ЖК&nbsp;Very",
    },
    preRooms: {
      type: String,
      require: false,
    },
  },
  emits: ["costMin"],
  data() {
    return {
      flats: [],
      squareFilter: [null, null],
      squareMinMax: [null, null],
      floorFilter: [null, null],
      floorMinMax: [null, null],
      costFilter: [null, null],
      costMinMax: [null, null],
      roomsFilter: null,
      roomsFilterActive: null,
      buildingsFilter: null,
      buildingsFilterActive: null,
      //decorationsFilter: [],
      //decorationsFilterActive: [],
      typesFilter: [{ flat: "Квартира" }, { apartment: "Лот" }],
      featsFilter: [
        { with_terrace: "Терраса" },
        { view_botanical_garden: "Вид на&nbsp;Ботанический сад" },
        { with_design_project: "Дизайн проект в подарок" },
      ],
      featsFilterActive: [],
      loading: true,
      mobileFilterShow: false,
    };
  },
  computed: {
    banners() {
      const banners = this.mainStore.state.data?.promo.banners;
      const out = [];
      for (let item in banners) {
        const data = banners[item];
        if (!data.activity) {
          continue;
        }
        const outItem = {
          data,
          component: Banner,
          isBanner: true,
        };
        if (data.slug === "ipoteka2") {
          outItem.component = BannerIT;
        }
        if (data.slug === "apartment") {
          outItem.component = BannerMonth;
        }
        out.push(outItem);
      }
      return out;
    },
    filteredFlats() {
      const flats = this.flats.filter(
        (flat) =>
          (this.roomsFilterActive
            ? flat.layout.room_count === this.roomsFilterActive
            : true) &&
          (this.buildingsFilterActive
            ? flat.building === this.buildingsFilterActive
            : true) &&
          /*(this.decorationsFilterActive.length
            ? this.decorationsFilterActive.some(
                (value) =>
                  (!value && !flat.properties.with_decoration) ||
                  value === flat.properties.with_decoration
              )
            : true) &&*/
          (this.typesFilterActive
            ? flat.layout.type == this.typesFilterActive
            : true) &&
          (flat.layout.type === 'flat' || flat.layout.type === 'apartment') &&
          (this.featsFilterActive.length
            ? this.featsFilterActive.every((value) => {
                return flat.properties[value];
              })
            : true) &&
          flat.layout.area >= this.squareFilter[0] &&
          flat.layout.area <= this.squareFilter[1] &&
          flat.floor.number >= this.floorFilter[0] &&
          flat.floor.number <= this.floorFilter[1] &&
          flat.discount_cost >= this.costFilter[0] &&
          flat.discount_cost <= this.costFilter[1]
      );
      if (this.flats.length > 0) {
        this.filterStore.setFilterSquare(this.squareFilter);
        this.filterStore.setFilterFloor(this.floorFilter);
        this.filterStore.setFilterCost(this.costFilter);
      }
      return flats;
    },
  },
  beforeMount() {
    if (this.$route.query.quantity !== undefined) {
      this.filterStore.setFilterRooms(this.$route.query.quantity);
    }

    this.roomsFilterActive = this.preRooms
      ? this.preRooms
      : this.filterStore.state.filterRooms;
    this.buildingsFilterActive = this.filterStore.state.filterBuildings;
    //this.decorationsFilterActive = this.filterStore.state.filterDecorations;
    this.typesFilterActive = this.filterStore.state.filterTypes;
    this.featsFilterActive = this.filterStore.state.filterFeats;
    this.squareFilter = this.filterStore.state.filterSquare;
    this.floorFilter = this.filterStore.state.filterFloor;
    this.costFilter = this.filterStore.state.filterCost;
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint + "building-objects/" + this.$project + "/properties"
      );
      let data = response.data.data;
      data.forEach(function (item) {
        switch (item.layout.room_count) {
          case "S":
            item.layout.room_count = "0";
            break;
          case "E2":
            item.layout.room_count = "2";
            break;
          case "E3":
            item.layout.room_count = "3";
            break;
          case "E4":
            item.layout.room_count = "4";
            break;
          case "E5":
            item.layout.room_count = "5";
            break;
        }
      });
      data = data.flatMap((flat) => {
        if (
          flat.floor.number <= 0 ||
          !this.$buildings[flat.building]?.isAvailable
        ) {
          return [];
        }
        this.$addPlanStub(flat);
        return [flat];
      });
      this.roomsFilter = _sortBy(_uniq(_map(data, "layout.room_count")));
      this.buildingsFilter = _sortBy(_uniq(_map(data, "building")));
      //this.decorationsFilter = [false, true];
      this.flats = data;
      this.setMinMaxs(true);

      const banners = await axios.get(this.$endpoint + "storage/9/promo/");
      if (banners.data.data?.promo?.banners.length > 0) {
        this.banner = banners.data.data.promo.banners[0];
      }

      this.loading = false;

      if (this.preRooms) {
        this.$emit(
          "costMin",
          _minBy(
            data.filter((flat) => {
              return flat.layout.room_count == this.roomsFilterActive;
            }),
            "discount_cost"
          ).discount_cost
        );
        this.clearFilter();
      }
    } catch (err) {
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
  methods: {
    roomsFilterControl(value) {
      if (this.preRooms) {
        this.$router.push({
          name: "Flat" + value,
        });
      } else {
        if (this.roomsFilterActive == value) {
          this.roomsFilterActive = null;
          this.$router.push({ "query.quantity": null });
        } else {
          this.roomsFilterActive = value;
          this.$router.push({ query: { quantity: value } });
        }
        this.filterStore.setFilterRooms(this.roomsFilterActive);
        this.setMinMaxs();
      }
    },
    buildingsFilterControl(value) {
      if (this.buildingsFilterActive == value) {
        this.buildingsFilterActive = null;
      } else {
        this.buildingsFilterActive = value;
      }
      this.filterStore.setFilterBuildings(this.buildingsFilterActive);
      this.setMinMaxs();
    },
    // decorationsFilterControl(value) {
    //   if (this.decorationsFilterActive.includes(value)) {
    //     this.decorationsFilterActive = this.decorationsFilterActive.filter(
    //       (option) => option !== value
    //     );
    //   } else {
    //     this.decorationsFilterActive.push(value);
    //   }
    //   this.filterStore.setFilterDecorations(this.decorationsFilterActive);
    //   this.setMinMaxs();
    // },
    typesFilterControl(value) {
      if (this.typesFilterActive == value) {
        this.typesFilterActive = null;
      } else {
        this.typesFilterActive = value;
      }
      this.filterStore.setFilterTypes(this.typesFilterActive);
      this.setMinMaxs();
    },
    featsFilterControl(value) {
      if (this.featsFilterActive.includes(value)) {
        this.featsFilterActive = this.featsFilterActive.filter(
          (option) => option !== value
        );
      } else {
        this.featsFilterActive.push(value);
      }
      this.filterStore.setFilterFeats(this.featsFilterActive);
      this.setMinMaxs();
    },
    clearFilter() {
      this.roomsFilterActive = this.preRooms ? this.preRooms : null;
      this.buildingsFilterActive = null;
      //this.decorationsFilterActive = [];
      this.featsFilterActive = [];
      this.filterStore.setFilterRooms(this.roomsFilterActive);
      this.filterStore.setFilterBuildings(this.buildingsFilterActive);
      //this.filterStore.setFilterDecorations(this.decorationsFilterActive);
      this.filterStore.setFilterFeats(this.featsFilterActive);
      this.setMinMaxs();
      this.$router.push({ "query.quantity": null });
    },
    costFilterFormat(value) {
      return (value / 1000000).toFixed(2) + " млн";
    },
    costFilterFormatPlain(value) {
      return (value / 1000000).toFixed(2);
    },
    costFilterFormatBack(value) {
      return parseFloat(value) * 1000000;
    },
    squareFilterFormat(value) {
      return value.toFixed(2) + " м&sup2;";
    },
    floorFilterFormat(value) {
      return value.toFixed(0);
    },
    setMinMaxs(mounted) {
      let data = this.flats;
      if (data.length) {
        let tempData = _filter(
          data,
          (el) =>
            (this.roomsFilterActive
              ? el.layout.room_count === this.roomsFilterActive
              : true) &&
            (this.buildingsFilterActive
              ? el.building === this.buildingsFilterActive
              : true) &&
            (this.featsFilterActive.length
              ? this.featsFilterActive.every((value) => {
                  return el.properties[value];
                })
              : true)
          /*&& (this.decorationsFilterActive.length
          ? this.decorationsFilterActive.some(
              (value) =>
                (!value && !el.properties.with_decoration) ||
                value === el.properties.with_decoration
            )
          : true)*/
        );
        if (tempData.length > 0) {
          data = tempData;
        }
        let squareMin = _minBy(data, "layout.area").layout.area;
        let squareMax = _maxBy(data, "layout.area").layout.area;
        let floorMin = _minBy(data, "floor.number").floor.number;
        let floorMax = _maxBy(data, "floor.number").floor.number;
        let costMin = _minBy(data, "discount_cost").discount_cost;
        let costMax = _maxBy(data, "discount_cost").discount_cost;
        this.squareMinMax = [squareMin, squareMax];
        this.floorMinMax = [floorMin, floorMax];
        this.costMinMax = [costMin, costMax];
        if (this.squareFilter[0] == null) {
          mounted = false;
        }
        if (!mounted) {
          this.$nextTick(() => {
            this.squareFilter = [squareMin, squareMax];
            this.floorFilter = [floorMin, floorMax];
            this.costFilter = [costMin, costMax];
          });
        }
      }
    },
    mobileFilterHide() {
      this.mobileFilterShow = false;
      gsap.to(window, {
        scrollTo: { y: this.$refs.main.querySelector(".count"), offsetY: 98 },
        duration: 0.5,
        ease: "power2.inOut",
      });
    },
    handleBannerClick(e, { url }) {
      if (url) {
        e.stopPropagation();
        return (window.location.href = url);
      }
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css" />
<style scoped>
@import "~@css/slider.css";

.plan-search {
  min-height: 100px;
  margin-top: -20px;
}

.plan-search > .row > div:nth-child(2)::before {
  content: "";
  position: absolute;
  top: -34px;
  bottom: 0;
  left: 34px;
  border-left: 1px solid #e5e5e5;
}

.left {
  width: 330px;
  min-height: 100%;
  padding-bottom: 60px;
}

.right {
  position: relative;
  margin-left: 55px;
}

.filter {
  position: sticky;
  top: 110px;
  margin-top: 35px;
}

.filter-control {
  margin: 0 0 8px;
}

.filter-control__label {
  margin-bottom: 17px;
  font-weight: normal;
  font-size: 16px;
}

.filter-control__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.filter-control__list.tags {
  gap: 8px;
  box-sizing: border-box;
  padding-bottom: 8px;
}

.filter-control__list:not(.tags) li {
  margin: 0 16px 16px 0;
  padding: 0;
}

.filter-control__list:not(.tags) li:last-child {
  margin-right: 0;
}

.filter-control__list li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  background: #fff;
  font-size: 14px;
  text-decoration: none;
}

.filter-control__list.tags li a {
  width: auto;
  height: 36px;
  border-radius: 200px;
  box-sizing: border-box;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.filter-control__list:not(.tags) li a::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s;
}

body.-notouch .filter-control__list li a:hover {
  border-color: #a6bbb3;
}

body.-notouch .filter-control__list li a:active {
  border-color: #205640;
}

.filter-control__list li.active a {
  border-color: #205640;
  background: #205640;
  color: #fff;
}

.filter-control__list li.active a::after {
  opacity: 0.2;
}

body.-notouch .filter-control__list li.active a:hover {
  border-color: #366753;
  background-color: #366753;
}

body.-notouch .filter-control__list li.active a:active {
  border-color: #1d4d3a;
  background-color: #1d4d3a;
}

.filter-inputs {
  display: none;
}

.filter-slider {
  position: relative;
  margin: 30px 12px 50px;
}

.filter-slider.mb {
  margin-bottom: 25px;
}

.filter-slider__label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  font-size: 14px;
  text-align: center;
  pointer-events: none;
}

.filter-slider__label span {
  display: inline-block;
  padding: 0 10px;
  background: #fff;
}

.filter-clear {
  font-size: 14px;
  text-align: center;
}

.filter-clear a {
  border-bottom: 1px dashed currentColor;
  color: #8c8f8c;
  text-decoration: none;
}

body.-notouch .filter-clear a:hover {
  color: #3f4440;
}

.filter-inputs__item {
  position: relative;
  flex-grow: 1;
  width: 50%;
}

.filter-inputs__item .form__input {
  position: relative;
  height: 44px;
  padding-right: 45px;
  padding-left: 34px;
  border-radius: 0;
}

.filter-inputs__item .form__input:focus {
  z-index: 1;
}

.filter-inputs__item:first-child .form__input {
  margin-right: -1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.filter-inputs__item:last-child .form__input {
  left: -1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.filter-inputs__label {
  position: absolute;
  top: 0;
  left: 13px;
  z-index: 3;
  color: #b2b4b3;
  font-weight: normal;
  font-size: 16px;
  line-height: 44px;
  pointer-events: none;
}

.filter-inputs__label + .filter-inputs__label {
  right: 13px;
  left: auto;
}

.filter-inputs__item:last-child .form__input {
  padding-left: 36px;
}

.filter-mobile-btn__show {
  position: fixed;
  bottom: 24px;
  left: 0;
  z-index: 49;
  width: 100%;
  text-align: center;
}

.filter-mobile-btn__hide {
  position: fixed;
  bottom: 24px;
  left: 0;
  z-index: 61;
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  transform: translateY(10px);
}

.filter-mobile-btn__hide.mobileFilterShow {
  opacity: 1;
  visibility: visible;
  transform: translateY(00);
}

.filter-mobile-btn__close {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.filter-mobile-btn__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 59;
  width: 100%;
  height: 100%;
  background: rgb(63 68 64 / 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s, visibility 0.1s;
}

.filter-mobile-btn__overlay.mobileFilterShow {
  opacity: 1;
  visibility: visible;
}

.count {
  margin-bottom: 30px;
  letter-spacing: -0.03em;
}

.flats-table {
  margin-left: -20px;
  border-top: 1px solid #e5e5e5;
}

.plan-search-header {
  position: relative;
  margin: -40px 0 30px;
}

.plan-search-header__background {
  position: relative;
  min-height: 280px;
  padding-bottom: 54%;
}

.plan-search-header__background img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.plan-search-header__background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.5) 100%
  );
}

.plan-search-header__title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (max-width: 575px) {
  .plan-search-header__background {
    padding-bottom: 0;
  }
}

@media (max-width: 1279px) and (min-width: 992px) {
  .flats-table :deep(.flats-table__mortgage) {
    display: none;
  }

  .flats-table :deep(thead th),
  .flats-table :deep(tbody.flats-table-found td) {
    padding-right: 15px;
    padding-left: 15px;
  }

  .flats-table :deep(thead th:last-child),
  .flats-table :deep(tbody.flats-table-found td:last-child) {
    padding-right: 0;
  }

  .flats-table :deep(.flats-table__image) {
    width: 121px;
    padding-right: 5px !important;
    padding-left: 20px !important;
  }

  .flats-table :deep(.flats-table__tag) {
    left: 15px;
  }

  .flats-table :deep(.flats-table__favourite) {
    padding-left: 5px !important;
  }
}
@media (max-width: 1279px) {
  .left {
    width: 260px;
  }

  .filter-control {
    margin-bottom: 14px;
  }

  .filter-control__list:not(.tags) li {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .filter {
    margin-top: 0;
  }

  .flats-mobile {
    margin-left: -20px;
    padding-left: 20px;
    border-top: 1px solid #e5e5e5;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .flats-mobile :deep(.abnner__content) {
    padding-left: 40px;
  }
}
@media (max-width: 767px) {
  .left {
    position: fixed;
    top: 40px;
    left: 0;
    z-index: 60;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: calc(100vh - 40px);
    min-height: auto;
    padding: 30px 0 117px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #f8f8f8;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
    transform: translateY(20px);
  }

  .left.mobileFilterShow {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .right {
    margin-left: 0;
  }

  .filter {
    position: relative;
    top: auto;
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .plan-search {
    margin-top: 0;
  }

  .plan-search > .row > div:nth-child(2)::before {
    display: none;
  }

  .count {
    margin-top: 0;
    margin-bottom: 27px;
  }

  .flats-mobile {
    margin-left: 0;
    padding-left: 0;
  }

  .filter-control__label {
    margin-bottom: 12px;
  }

  .filter-slider {
    margin: 0 0 24px;
  }

  .filter-slider .slider-target {
    display: none;
  }

  .filter-slider__label {
    position: relative;
    margin-bottom: 12px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }

  .filter-slider__label span {
    padding: 0;
    background: transparent;
  }

  .filter-inputs {
    display: flex;
  }

  .filter-clear {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  .filter {
    max-width: 100%;
  }
}
</style>
